import React from "react";

class SectionTitle extends React.Component {
  render() {
    return (
      <h2 className="text-center Title__Anton">{this.props.title}</h2>
    );
  }
}

export default SectionTitle;
